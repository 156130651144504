// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik+Spray+Paint&family=Varela+Round&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  background-color: #fffff2;
  /* background-color: #ffefcf; */
  font-family: 'Varela Round', sans-serif;
}

body {
  background-color: black;
  font-family: 'Varela Round', sans-serif;
}




/* /////////////////////////////////////////////////////////////////////////////////// */

.navBar {
  overflow: hidden;
  background-color: #F3F0EA;
  color: #707173;
  /* padding-right: 10%; */
}

.navBar a {
  float: right;
  display: block;
  color: #707173;
  padding: 30px 60px;
  text-decoration: none;
  font-size: 20px;
} 


.navBar a:hover {
  /* border-bottom: 3px solid #FF5DAE; */
  color: black;
}



`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,+BAA+B;EAC/B,uCAAuC;AACzC;;AAEA;EACE,uBAAuB;EACvB,uCAAuC;AACzC;;;;;AAKA,wFAAwF;;AAExF;EACE,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;EACd,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;AACjB;;;AAGA;EACE,sCAAsC;EACtC,YAAY;AACd","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik+Spray+Paint&family=Varela+Round&display=swap');\r\n\r\n.App {\r\n  text-align: center;\r\n  background-color: #fffff2;\r\n  /* background-color: #ffefcf; */\r\n  font-family: 'Varela Round', sans-serif;\r\n}\r\n\r\nbody {\r\n  background-color: black;\r\n  font-family: 'Varela Round', sans-serif;\r\n}\r\n\r\n\r\n\r\n\r\n/* /////////////////////////////////////////////////////////////////////////////////// */\r\n\r\n.navBar {\r\n  overflow: hidden;\r\n  background-color: #F3F0EA;\r\n  color: #707173;\r\n  /* padding-right: 10%; */\r\n}\r\n\r\n.navBar a {\r\n  float: right;\r\n  display: block;\r\n  color: #707173;\r\n  padding: 30px 60px;\r\n  text-decoration: none;\r\n  font-size: 20px;\r\n} \r\n\r\n\r\n.navBar a:hover {\r\n  /* border-bottom: 3px solid #FF5DAE; */\r\n  color: black;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
