// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik+Spray+Paint&family=Varela+Round&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @font-face {
    font-family: 'FBGesher';
    src: url('../../fonts/FbGesher-Regular.otf'),
         url('../../fonts/FbGesher-Bold.otf');
  }
  #header_p {
    font-family:'FBGesher' ;
      padding-top: 190px;
      font-size: 70px;
      text-shadow: 0.04em 0.001em 0.01em #ffd359;
  } */
.words {
    font-family: 'FBGesher', 'Rubik Spray Paint';
}

#pp {
    margin-top: -10px;
    /* padding-right: 20%; */
    /* font-family: 'Varela Round', sans-serif; */
    font-family: 'FBGesher';
    text-shadow: 0.02em 0.02em 0.01em #000000, -0.02em -0.02em 0.01em #000000;
    color: #b29752;
    font-size: 33px;
}
#logo1 {
    width: 10rem;
}

.div1 {
    font-size: x-large;
    font-weight: 900;
    color: black;
}

.div2 {
    font-size: x-large;
    color: black;
    width: 30%;
    margin-left: 30%;
    text-align: right;
}
.div3 {
    font-size: xx-large;
    font-weight: 900;
    color: black;
}
.div4 {
    font-size: x-large;
    color: black;
}

@media (max-width: 991px) {
    .words {
        margin-top: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/style/about.css"],"names":[],"mappings":"AACA;;;;;;;;;;KAUK;AACL;IACI,4CAA4C;AAChD;;AAEA;IACI,iBAAiB;IACjB,wBAAwB;IACxB,6CAA6C;IAC7C,uBAAuB;IACvB,yEAAyE;IACzE,cAAc;IACd,eAAe;AACnB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,UAAU;IACV,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Rubik+Spray+Paint&family=Varela+Round&display=swap');\r\n/* @font-face {\r\n    font-family: 'FBGesher';\r\n    src: url('../../fonts/FbGesher-Regular.otf'),\r\n         url('../../fonts/FbGesher-Bold.otf');\r\n  }\r\n  #header_p {\r\n    font-family:'FBGesher' ;\r\n      padding-top: 190px;\r\n      font-size: 70px;\r\n      text-shadow: 0.04em 0.001em 0.01em #ffd359;\r\n  } */\r\n.words {\r\n    font-family: 'FBGesher', 'Rubik Spray Paint';\r\n}\r\n\r\n#pp {\r\n    margin-top: -10px;\r\n    /* padding-right: 20%; */\r\n    /* font-family: 'Varela Round', sans-serif; */\r\n    font-family: 'FBGesher';\r\n    text-shadow: 0.02em 0.02em 0.01em #000000, -0.02em -0.02em 0.01em #000000;\r\n    color: #b29752;\r\n    font-size: 33px;\r\n}\r\n#logo1 {\r\n    width: 10rem;\r\n}\r\n\r\n.div1 {\r\n    font-size: x-large;\r\n    font-weight: 900;\r\n    color: black;\r\n}\r\n\r\n.div2 {\r\n    font-size: x-large;\r\n    color: black;\r\n    width: 30%;\r\n    margin-left: 30%;\r\n    text-align: right;\r\n}\r\n.div3 {\r\n    font-size: xx-large;\r\n    font-weight: 900;\r\n    color: black;\r\n}\r\n.div4 {\r\n    font-size: x-large;\r\n    color: black;\r\n}\r\n\r\n@media (max-width: 991px) {\r\n    .words {\r\n        margin-top: 0px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
